<template>
  <div class="chat-container">
    <div class="chat-window">
      <div class="messages">
        <div v-for="(message, index) in messages" :key="index"
          :class="{ 'user-message': message.role === 'user', 'system-message': message.role === 'system' }">
          {{ message.content }}
        </div>
      </div>
      <!-- Loader -->
      <div v-if="loading" class="loader"><svg xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto;  display: block;" width="100%" height="100%"
          viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <rect fill="#f5593d" x="17" y="17" width="30" height="30" rx="42" ry="42">
            <animate attributeName="x" dur="5s" repeatCount="indefinite"
              keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" values="17;53;53;53;53;17;17;17;17"
              begin="-4.583333333333333s"></animate>
            <animate attributeName="y" dur="5s" repeatCount="indefinite"
              keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" values="17;53;53;53;53;17;17;17;17"
              begin="-3.333333333333333s"></animate>
          </rect>
          <rect fill="white" x="17" y="17" width="30" height="30" rx="42" ry="42">
            <animate attributeName="x" dur="5s" repeatCount="indefinite"
              keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" values="17;53;53;53;53;17;17;17;17"
              begin="-2.9166666666666665s"></animate>
            <animate attributeName="y" dur="5s" repeatCount="indefinite"
              keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" values="17;53;53;53;53;17;17;17;17"
              begin="-1.6666666666666665s"></animate>
          </rect>
          <rect fill="#000000" x="17" y="17" width="30" height="30" rx="42" ry="42">
            <animate attributeName="x" dur="5s" repeatCount="indefinite"
              keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" values="17;53;53;53;53;17;17;17;17" begin="-1.25s">
            </animate>
            <animate attributeName="y" dur="5s" repeatCount="indefinite"
              keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" values="17;53;53;53;53;17;17;17;17" begin="0s">
            </animate>
          </rect>
        </svg>Loading...</div>
    </div>
    <div class="input-container ">
      <input type="text" v-model="prompt" @keyup.enter="generateText" placeholder="Enter your prompt...">
      <button @click="generateText">Send</button>

    </div>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

  </div>

</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      prompt: '',
      messages: [],
      loading: false, // Initialize loading state
      errorMessage: '' // Initialize error message
    };
  },
  methods: {
    async generateText() {
      // Clear any previous error message
      this.errorMessage = '';

      // Check if prompt is empty
      if (!this.prompt.trim()) {
        // Set error message and return
        this.errorMessage = 'Please enter a prompt.';
        return;
      }

      // Set loading state to true when sending request
      this.loading = true;

      // Ajouter le message de l'utilisateur
      this.messages.push({ role: 'user', content: this.prompt });

      try {
        // Créer une instance Axios pour activer le streaming
        const axiosInstance = axios.create({
          responseType: 'stream',
          headers: { 'Content-Type': 'application/json' }
        });
        // Envoyer le prompt à l'API en activant le streaming
        axiosInstance.post('https://ai-api.zokas.tech/api/generate', {
          model: 'mistral',
          prompt: this.prompt,
          stream: false
        }).then(responseData => {
          if (this.stream) {
            const messages = responseData.data.toString().split('\n');
            const messageContent = '';

            // console.log(messages);

            Array.prototype.forEach.call(messages, message => {
              console.log(message)
              var messageData = + JSON.parse(message);
              this.messageContent = messageData.response
            });
            this.messages.push({ role: 'system', content: messageContent });
          } else {
            console.log(responseData.data);
            this.messages.push({ role: 'system', content: JSON.parse(responseData.data.toString()).response });
          }
        }).finally(() => {
          // Set loading state to false after receiving response
          this.loading = false;
        });

        // Effacer le champ de saisie après l'envoi
        this.prompt = '';
      } catch (error) {
        console.error('Error:', error);
        // Set loading state to false in case of error
        this.loading = false;
      }
    }
  }
};
</script>

<style>

/* Add error message styles */
.error-message {
  color: red;
  margin-top: 5px;
    background-color: rgba(228, 185, 185, 0.8);
}

/* Add loader styles */
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(228, 185, 185, 0.8);
  padding: 10px;
  border-radius: 5px;
  
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-window {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.messages {
  display: flex;
  flex-direction: column;
}

.user-message {
  align-self: flex-end;
  background-color: #f5593d2b;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.system-message {
  align-self: flex-start;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.input-container {
  display: flex;
  padding: 10px;
}

input[type="text"] {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

button {
  padding: 8px 15px;
  background-color: #f5593d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>




